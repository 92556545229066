.process {
    background-color: $sub-ligth;

    .container {
        padding-top: 5rem;
        padding-bottom: 5rem;

        @media (min-width: 992px) {
          padding-top: 9rem;
          padding-bottom: 9rem;
        }
    }
    
    @media (min-width: 992px) {
        background-image: url(../img/proceso-bg-left.svg), url(../img/proceso-bg-right.svg);
        background-repeat: no-repeat, no-repeat;       
        background-position: left center, right center;
        background-size: auto, auto;   
    }

    @include e('divider') {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
        background-size: 100% 100px;
        top: 0;
        height: 100px;
        z-index: 1;
    }
}