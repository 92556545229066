.stats {

   &-section {
        background-color: $sub-ligth;
        margin-bottom: 9rem;

        .container {
            padding-top: 9rem;
            padding-bottom: 9rem;

            .stats__icons {
              font-size: 4.375rem;
            }
        }

        @include e('divider') {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
            background-size: 100% 100px;
            top: 0;
            height: 100px;
            z-index: 1;
        }
    }

    @include e('icons') {
        font-size: 70px;
    }
}
