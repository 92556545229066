.section {    

    padding-top: 5rem;
    padding-bottom: 5rem;
    
    @media (min-width: 992px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
    
    @include e('title') {
        color: $main-dark;
        font-size: calc(34px + 1.8vw);
        @media (min-width: 992px) {
            font-size: calc(28px + 1.4vw);
        }
        @media (min-width: 1200px) {
            font-size: calc(30px + 1.5vw);
        }
    }

    @include e('text') {
        font-size: 16px;
        line-height: 1.4;
        @media (min-width: 992px) {
            font-size: 17px;
        }
        @media (min-width: 1200px) {
            font-size: 20px;
        }
    }
}