.btn {
  padding: 0.375rem 1.5rem;

  &-primary {
    color: $white;
    border-color: $main;
    background: $main;

    &:hover {
      color: $white;
      border-color: darken($main, 5%);
      background: darken($main, 5%);
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      color: $white;
      background-color: $main;
      border-color: $main;
    }

    &:focus {
      color: $white;
      background-color: $main;
      border-color: $main;
      box-shadow: none;
    }
    
    &.disabled {
      color: $text;
      background-color: $lighten;
      border-color: $lighten;
      box-shadow: none;
    }
  }
}