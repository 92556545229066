
.font-heading {
  font-family: $font-heading;
}

strong {
  font-weight: 600;
}
.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}


.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 1.2;
}