.slider {    
    @include e('text') {
        width: 120px;
        @media (min-width: 1200px) {
            width: 160px;
        }
    }

    &.slider-horizontal {
        width: 170px;
        @media (min-width: 1200px) {
            width: 260px;
        }
    }  
}

.monetizar {
    small {
        font-size: 12px;
    }
}