// Color Palette
$white: #FFF !default;
$black: #000 !default;
$text: #404040 !default;
$text-light: #717171 !default;
$bg: #F2F2F2 !default;
$gray-light: #BBBBBB !default;
$lighten: #D0CCC9 !default;
$sub-ligth: #F7F5F1;
$neutral: #fffcf4;
$grey: $lighten !default;
$success: $success;
$warning: #856404;
$info: #1c72a3;
$info-bg: #f2f6ff;
$danger: $danger;
$danger-bg: #FFDDDA;


$main: $primary !default;
$main-dark: #605841 !default;
$main-ligth: #ebe0d4 !default;
$secondary: #9A352A !default;
$red: $danger !default;

$theme-colors: () !default;

$theme-colors: (
  main: $main,
  main-dark: $main-dark,
  red: $red,
  lighter: $main-ligth,
);


@each $item,
$color in $theme-colors {
  @each $value in $color {
    .bg-#{$item} {
      background-color: $value !important;
    }
  }
}

@each $item,
$color in $theme-colors {
  @each $value in $color {
    .color-#{$item} {
      color: $value !important;
    }
  }
}

@each $item,
$color in $theme-colors {
  @each $value in $color {
    .fill-#{$item} {
      fill: $value !important;
    }
  }
}

@each $item,
$color in $theme-colors {
  @each $value in $color {
    .link-#{$item} {
      color: $value !important;
    }
  }
}