.benefits-one {
    background-color: $neutral;

    @media (min-width: 1400px) {
        background-image: url(../img/beneficio-piel-bg.svg);
        background-repeat: no-repeat;       
        background-position: right center;
        background-size: auto;   
    }
}

.benefits-two {


    @media (min-width: 1400px) {
        background-image: url(../img/beneficio-centro-bg.svg);
        background-repeat: no-repeat;       
        background-position: left bottom;
        background-size: auto;   
    }
}