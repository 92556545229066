$font-main: 'Poppins', sans-serif;;
$font-heading: 'Noto Serif JP', serif;

body {
  font-family: $font-main;
  color: $text;
  font-size: 16px;
  padding-top: 4.8rem; 
  min-height: 75rem;
}

.mt-6 {
  margin-top: 3rem !important;

  @media (min-width: 992px) {

    margin-top: 6rem !important;
  }
}

a {
  color: $main;

  &:hover {
    color: $secondary;
  }

  &.deco {
    text-decoration: underline;
  }
}

iframe {
  height: 100vh;
  width: 100%;
}
.full {
  height: 350px;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
}

@media (min-width: 992px) {

  .full {
    min-height: 100vh;
    background-size: cover;
    background-attachment: scroll;
  }
}

.slider-handle {
  background-image: linear-gradient(to bottom, $main, $main-dark);
  background-color: $main;
}