.price {
  &-section {
    background-color: $sub-ligth;
    
    .container {
      padding-top: 5rem;
      padding-bottom: 5rem;

      @media (min-width: 992px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
      }
    }

    @include e("divider") {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
      background-size: 100% 100px;
      top: 0;
      height: 100px;
      z-index: 1;
    }

    @include e("divid") {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0zMjAgMjhjMzIwIDAgMzIwIDg0IDY0MCA4NCAxNjAgMCAyNDAtMjEgMzIwLTQydjcwSDBWNzBjODAtMjEgMTYwLTQyIDMyMC00MnoiLz48L2c+PC9zdmc+);
      background-size: 100% 100px;
      bottom: 0;
      height: 100px;
      z-index: 1;
    }
  }

  border-radius: 12px;
  background: $white;

  @media (min-width: 992px) {
    min-height: 100%;
  }

  @include e("subtitle") {
    color: $text-light;
  }

  @include e("amount") {
    font-size: 22px;
    line-height: 1.4;
    @media (min-width: 992px) {
      font-size: 26px;
    }
  }

  @include m("bg") {
    background-color: $main;
    color: $white;
    background-image: url(../img/price-bg.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    a {
      color: $white;
    }

    @include e("title") {
      color: $white !important;
    }

    @include e("subtitle") {
      color: $white;
    }
  }
  .material-icons {
    color: $success;
  }
}
