header {
    background: $white;

    .nav-link {
        @media (min-width: 992px) {
            font-size: 18px;
            padding: .5rem 24px !important;
        }
    }

    .navbar-toggler {
        svg {
            fill: $main-dark;
        }

        &:focus {
            box-shadow: none;
        }
    }
}