.hero {
    padding-bottom: 4rem;
    
    @media (min-width: 992px) {
        background-color: $white;
        background-image: url(../img/hero-bg.svg), url(../img/video-hero-bg.svg);
        background-position: -130px center, 600px top;
        background-repeat: no-repeat, no-repeat;    
        background-size: 308px 100%, 700px 450px;        
        

        padding-bottom: 9rem;
    }

    @media (min-width: 1200px) {
        background-position: -130px center, 700px top;   
        background-size: 308px 100%, 800px 500px;
    }

    @media (min-width: 1400px) {
        background-position: -120px center, 800px top;
        background-size: 308px 100%, auto;
    }

    @media (min-width: 1600px) {
        background-position: -68px center, right top;
    }

    @include e('subtitle') {
        font-size: calc(13px + .3vw);
    }

}