.contact {

    @media (min-width: 1200px) {
        background-image: url(../img/contacto-bg.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto;
    }
}

.contact-message-ok {
    background-color: $neutral;

    @media (min-width: 1400px) {
        background-image: url(../img/beneficio-piel-bg.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto;
    }
}
