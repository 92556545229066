.accordion {
    &-item {
        border-radius: 12px !important;
        border: none;

        .accordion-button {
            border-radius: 12px !important;
            padding: 32px 24px;
            font-weight: 600;
            font-size: 18px;
            color: $text;

            &:not(.collapsed),
            &:focus {
                background: $white;
                box-shadow: none;
                color: $main;

                &:after {
                    color: $main;
                }
            }
        }
    }
}