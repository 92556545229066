.modal-fullscreen .modal-content {
  background-color: transparent;

  .video {

    height: 100%;

    &__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
        padding: 24px;
       
        @media (min-width: 992px) {
            padding: 64px;
        }
    }

    &__rep {
        position: relative;
        z-index: 1;
        max-width: 1280px;
        width: 100%;
        height: auto;
        outline: none;
    }
  }
  
  .btn-close {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 16px;
    background-color: $white;
  }
}
